.App {
  text-align: center;
  font-family: Lato;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #98D2D9;
  font-weight: 300; 
}
::-moz-placeholder { /* Firefox 19+ */
  color: #98D2D9;
  font-weight: 300; 
}
:-ms-input-placeholder { /* IE 10+ */
  color: #98D2D9;
  font-weight: 300; 
}
:-moz-placeholder { /* Firefox 18- */
  color: #98D2D9;
  font-weight: 300; 
}
.top {
  position:absolute;
}
.input_box {
  position: relative;
}
.headerBottom {
  background-image:url('../images/header_bg.jpg');
  height:80px;
  width:100%;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:bottom;
}
.signup {
	background-color: #263238;
	height: 100px;
}
.dummy {
  clear: both;
}
.signupButton {
	margin-top: 25px;
}
.biggerSherpaMan {
  display: none;
}
.half {
	text-align:center;
}
.map {
   background-color: #263238;
   color: white;
   line-height: 25px;
   padding:20px;
}
.successIntro {
  line-height: 30px;
  font-weight: 300;
  margin-top: 15px;
}
.noEmail {
  line-height: 50px;
}
.australia{
  background-image:url('../images/map.png');
  height:200px;
  width:197px;
  background-repeat:no-repeat;
  background-size:contain;
  margin:0 auto;
  margin-top: 70px;
  margin-bottom: 30px;
}
.appLogo{
  height:40px;
  width:123px;
  margin: 15px 5px 25px 5px;
}
.socialFB {
  width: 8px;
  height:15px;
  margin-left:10px;
  margin-right:5px;
  margin-top: 20px
}
.socialLogo{
  width: 15px;
  height: 15px;
  margin-left:10px;
  margin-right:5px;
  margin-top: 20px
}
.itunes {
  width: 122px;
  height: 40px;
  background-image:url('../images/apple.png');
}
.googleplay {
  width: 123px;
  height: 40px;
  background-image:url('../images/googleplay.png');
}
.header {
  background-color: #0097a9;
  padding: 14px;
  color: white;
  background-size:contain;
  background-repeat:no-repeat;
  background-position:bottom;
  
}

.header h1 {
    font-weight: 300;
    font-size: 33px;
    line-height: 44px;
  }

.process {
  color: black;
}

.process h4 {
  color: #0097a9;
}

.mapTitle {
  font-weight: 300;
  font-size: 20px;
  color: #0097a9;
  padding: 40px;
  line-height: 40px;
}
.everyonTitle {
  background-image:url('../images/everyone_sherpa.png');
  height:12px;
  margin-top: 3px;
  width:140px;
  float:left;
  background-size: contain;
  background-repeat:no-repeat;
}
.facebook {
  background-image:url('../images/facebook.png');
  height:20px;
  width:20px;
  background-repeat:no-repeat;
  background-size:contain;
  float:right;
}
.instagram {
  background-image:url('../images/instagram.png');
  height:20px;
  width:20px;
  background-repeat:no-repeat;
  background-size:contain;
  float:right;
  margin-right:10px;
}
.linkedin {
  background-image:url('../images/linkedin.png');
  height:20px;
  width:20px;
  background-repeat:no-repeat;
  background-size:contain;
  float:right;
}
.logo {
  background-position: -1350px -1211px;
  width:150px;
  height:58px;
  clear:both;
  background-repeat:no-repeat;
  margin:0 auto;
  margin-top:50px;
  margin-bottom:50px;
  position: relative;
  top: 30px;
}
.bottomArrow{
  background-image:url('../images/bottom_arrow.png');
  width:110px;
  height:108px;
  clear:both;
  background-size:contain;
  background-repeat:no-repeat;
  margin:0 auto;
  position:relative;
  top: 10px;
}
.sherpaMan{
  width: 290px;
  height: 247px;
  background-image:url('../images/small_man.png');
  clear:both;
  margin:0 auto;
  margin-top:50px;
}
.processDesc {
  min-height: 70px;
}
.signupDesc {
  font-size: 16px;
  margin-bottom: 50px;
  margin-top: 15px;
  color: black;
} 

.pcIntro {
	line-height: 45px !important;
    clear: both;
    font-size: 30px;
    font-weight: 300;
}
.requirement {
   background-image:url('../images/requirement_bg.jpg');
   height:100%;
   background-size:contain;
   color: white;
   padding: 20px;
  
}

.requirement  h1 {
     margin: 70px 0px 30px 0px;
     font-weight: 300;
}

.requirement .requiredItem {
     margin: 30px 0px;
     min-height: 150px;
}


.pc {
  width: 272px;
  height: 230px;
  background-image:url('../images/pc.png');
  margin: 0 auto;
  margin-bottom: 15px;
}

.left {
  width: 260px;
  height: 138px;
  background-image:url('../images/left.png');
  margin: 0 auto;
  margin-bottom: 15px;
}

.right {
 width: 260px;
  height: 138px;
  background-image:url('../images/right.png');
  margin: 0 auto;
  margin-bottom: 15px;
}
.feature {
  padding: 20px 40px 50px 40px;
  color: black;
  line-height: 30px;
}

.feature h1 {
   color: #0097a9;
   line-height: 55px;
   font-weight: 300;
  }

.feature h4 {
   color: #0097a9;
   line-height: 35px;
   font-weight: 700;
   font-size:18px;
   margin-bottom: 0px;
  }

.feature p {
   line-height: 25px;
  }

.payment {
  width: 100px;
  height:86px;
  background-image:url('../images/payment.png');
  margin: 0 auto;
}
.quote {
  width: 100px;
  height:86px;
  background-image:url('../images/quote.png');
  margin: 0 auto;
}
.loop {
  width: 100px;
  height:86px;
  background-image:url('../images/loop.png');
  margin: 0 auto;
}
.rate {
  width: 100px;
  height:86px;
  background-image:url('../images/rate.png');
  margin: 0 auto;
}
.locations {
  width: 100px;
  height:86px;
  background-image:url('../images/locations.png');
  margin: 0 auto;
}
.options {
  width: 100px;
  height:86px;
  background-image:url('../images/options.png');
  margin: 0 auto;
}
.footer {
  clear: both;
  background-color: #e3ecf0;
  padding:20px;
  min-height: 100px;
} 
.footer  a {
  	color: #0097a9;
}

.footerContents {
	margin:0 auto;
	font-size:13px;
} 

.lamma {
  width: 117px;
  height: 45px;
  background-image:url('../images/lamma.png');
  position: absolute;
}
.shopContainer {
  position: absolute;
  top: 100px;
  height: 357px;
  width: calc(100vw - 742px);
  left: 728px;
  overflow: hidden;
  display: none;
}

.shop {
  position: absolute;
  width: 420px;
  height: 357px;
  background-image:url('../images/shop.png');
  display: none;
}

.everyonTitle { 
	float: right;
	margin-right: 30px;
	top: 12px;
	position:relative;
}
.facebook {
	top: 10px;
	position:relative;
}
.instagram {
	top: 10px;
	position:relative;
}

.clear {
	clear: both;
}

.headerIntro {
	margin-top: 70px;
}
.headerIntro h5{
	letter-spacing: 2px;
	font-size: 13px;
}

.headerIntro h1{
	line-height: 40px;
}
.signupButton {
  color: white !important;
  font-size: 14px !important;
  width: 100%;
}

.apps a {
   float: left;
  }

.apps { 
    height: 50px;
    width: 270px;
    margin: 0 auto;
}

.social {
    width:70px;
    margin: 0 auto;
    position: relative;
    top: -20px;
}
.featureIcon{
  width: 100%;
  height:80px;
}
.completed {
  max-width: 500px;
  margin-top: 70px;
  margin-left: 20px;
  
}

.completed h1 {
    font-weight: 200;
    font-size: 48px;
    line-height: 65px;
}
 

@media screen and (min-width: 900px) {

   .footerContents {
      width: 900px;
    }

	.footer {
	  clear: both;
	}
 	 
	 .shopContainer {
	 	display: block;
	 }
	 
	 .shop {
	  display: block;
	}

	 .left, .right {
	 	margin-top: 20px;
	 }
	 .featureIcon{
	 	float: left;
		width: 100px;
		height:150px;
		margin-right: 20px;
    		margin-left: 20px;
	 }
	
	.signupDesc{
		margin-left: 30px;
    		margin-right: 30px;
	}
	.homepage {
	  float: left;
	  margin-top: 25px;
      margin-right: 10px;
	}
	
	.apps {
	  float: left;
	}
	
	.social {
		float: left;
		margin-top: 10px;
		top: 0px;
	}

    .headerContents {
      max-width: 960px;
      margin: 0 auto;
      position: relative;
    }
	.header {
		min-height: 450px;
		position: relative;	 
		
	 }

	.header h1 {
      font-weight: 300;
      font-size: 40px;
		  line-height: 50px;
    }
	.logo {
		position: absolute;
		margin: 0;
		top: 10px;
	}
	.linkedin {
		top: 10px;
		position:relative;
	}
	.headerIntro {
	  float: left;
      width: 300px;
      text-align: left;
      padding: 20px;
    }
    .biggerSherpaMan {
	  display: block;
	  width: 308px;
	  height: 468px;
	  background-image:url('../images/big_man.png');
	  position: absolute;
	  right: 50px;
	  top: 125px;
	}
    .cloud {
	  width: 540px;
	  height: 193px;
	  background-image:url('../images/cloud.png');
	  position: absolute;
      right: 0;
      top: 0px;
	}
    .signupForm {
      float: left;
    }
	.sherpaMan {
	  display: none;
	}
	.everyonTitle { 
		float: right;
		margin-right: 30px;
		top: 10px;
		position:relative;
	}
	.float {
		float: left;
		width: 33%;
  		margin-bottom: 30px;
  		padding: 0px 20px 60px 20px;
  		min-height: 230px;
	}
	.process {
	  height: 490px;
	}
	.feature, .requirement {
	  clear: both;
	}
	.feature {
  		padding: 60px 40px 50px 40px;
  	}
	.requirement {
	  min-height: 500px;
	  background-size:cover;
      background-image:url('../images/driver.jpg');
      padding-top: 60px;
      margin-top: 30px;
	}
	.requiredItem {
		float: left;
		width: 33%;
		height: 150px;
	}
	.half {
		float: left;
		width: 50%;
		text-align: left;
	}
	.signup {
		height: 170px;
		margin-top: 0px;
	}	
	.signupButton {
		margin-top: 60px;
	}
	
	.mapTitle {
	  font-weight: 300;
	  font-size: 25px;
	  color: #0097a9;
	  padding: 70px 0px 25px 0px;
	  line-height: 40px;
	}
	.mapContents {
		width: 60%;
		float: right;
		text-align: left;
		padding-right: 10px;
	}
	.processDesc {
		max-width: 310px;
		margin: 0 auto;
	}
	.headerIntro h1{
		line-height: 60px;
	}
	.map {
	  height: 350px;
    }
	.wrap {
	  max-width: 960px;
      margin: 0 auto;
	}
	.australia {
		width: 40%;
		float: left;
	}
}


@media screen and (min-width: 988px) {
	.shopContainer {
		width: calc(100vw - 742px - (100vw - 988px)/2);
		left: 728px;
	}
}

@media screen and (max-width: 400px) {
	.lamma {
		display: none;
	}
	.everyonTitle { 
		float: none;
	}
}