.signupform .field {
  width: 278px;
  border: none;
  height: 50px;
  background-color: #008395;
  margin-top: 10px;
  padding-left: 16px;
  color: #ffffff;
}

.signupform .vehicleField {
  width: 300px;
  border: none;
  height: 50px;
  background-color: #008395;
  margin-top: 10px;
  padding-left: 16px;
  color: #ffffff;
}
.signupform ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: white !important;
}
.signupform ::-moz-placeholder { /* Firefox 19+ */
  color: white !important;
}
.signupform :-ms-input-placeholder { /* IE 10+ */
  color: white !important;
}
.signupform :-moz-placeholder { /* Firefox 18- */
  color: white !important;
}
.signupform .hide_error_box {
  position: relative;
  width: 300px;
  margin: 0 auto;
  text-align: left;
  background-color: #FF9900;
  padding: 16px; 
  opacity: 0; 
   display:none;
}
.signupform .leftVan {
  float: left;
  padding-left: 15px;
  padding-top: 5px;
  font-weight: 300; 
}
.signupform .rightVan {
  float: right;
  padding-left: 15px;
  text-decoration: underline;
  padding-right: 15px;
  padding-top: 7px;
  font-weight: bold;
  font-size: 12px;
}
.signupform a {
  color: white;
}
.signupform .vanLabel a:hover {
  color: #efefef;
}
.signupform .vanLabel {
    position: absolute;
    width: 100%;
    top: 20px;
    z-index: 9999;
    height: 40px;
    background-color: #008395;
}
.signupform .clear {
  clear: both;
}
.signupform .show_error_box {
  position: relative;
  width: 267px;
  margin: 0 auto;
  text-align: left;
  background-color: #FF9900;
  padding: 16px;
  display:block; 
 }

.signupform .first_name, .signupform .last_name, .signupform .input_box {
  position: relative;
  width: 300px;
  margin: 0 auto;
  overflow: hidden;	
}

.signupform .description {
  text-align: center;
  font-size: 12px;
  padding-top: 10px;
  line-height: 20px;
  font-weight: 300;
  width: 310px;
  margin: 0 auto;
  position: relative;
}

.signupform .description strong {
  font-weight: 500;
}

.signupform .email_box {
  clear: both;
  width: 300px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;	
}
.signupform .vehicleTypeHolder {
  width: 250px;
  background-color: transparent;
  position: absolute;
  bottom: 1px;
  z-index: 1000;
  text-align: left;
  padding: 16px;
  color: #98D2D9;
  font-weight: 300; 
}
.signupform .vehicleType {
  width: 250px;
  background-color: transparent;
  position: absolute;
  bottom: 5px;
  z-index: 1000;
  text-align: left;
  padding: 16px;
  color: white;
}
.signupform .requiredMark {
	position: absolute;
	color: white;
    right: 10px;
    top: 20px;
    z-index: 1000;
}
.signupform .dropdown {
	position: absolute;
	right: 35px;
    top: 28px;
    z-index: 1000;
    background-image:url('../images/drop_down.png');
    height:13px;
    width:10px;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:bottom;
}
.signupform .close {
	position: absolute;
	right: 10px;
    top: 20px;
    z-index: 1000;
    background-image:url('../images/close.png');
    height:12px;
    width:12px;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:bottom;
}
.signupform .redButton {
  background-color: #c4105a;
}

.signupform .submitButton {
  width: 300px;
  text-align: center;
}
.signupform .container {
  display: block;
  position: relative;
  padding-left: 0px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 300;;
}

/* Hide the browser's default checkbox */
.signupform .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


/* Create a custom checkbox */
.signupform .checkmark {
  position: absolute;
  top: 2px;
  left: 20px;
  height: 14px;
  width: 14px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.signupform container:hover input ~ .checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.signupform .container input:checked ~ .checkmark {
  background-color: white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.signupform .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.signupform .container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.signupform .container .checkmark:after {
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid #0097a9;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.signupform {
  margin-top: 20px;
}

@media screen and (min-width: 900px) {
	
	.signupform  {
		width: 400px;
		height: 430px;
		float: left;
		position: relative;
		top: 20px;
	}
	
	.signupform .field {
	  width: 378px;
	  border: none;
	  height: 50px;
	  background-color: #008395;
	  margin-top: 10px;
	  padding-left: 16px;
	  z-index: 200;
      position: relative;
	}
	
	.signupform .vehicleField {
	  width: 400px;
	  border: none;
	  height: 50px;
	  background-color: #008395;
	  margin-top: 10px;
	  padding-left: 16px;
	  z-index: 200;
      position: relative;
	}
	
	.signupform .hide_error_box {
 		margin: 0;
	  	width: 100%;
  		opacity: 0;
  		display:block;
	}
	.signupform .show_error_box {
  		margin: 0;
	  	width: 368px;
 	}
	
	.signupform .first_name, .signupform .last_name, .signupform .input_box, .signupform .email_box {
	  margin: 0;
	  width: 100%;
	  overflow: hidden;	
	}
	
	.signupform .first_name {
		float: left;
		width: 190px;
		margin-right: 10px;
		overflow: hidden;		
	}
	
	.signupform .first_name .field {
  		width: 168px;
		overflow: hidden;	
  	 }
	
	.signupform .last_name {
		float: left;
		width: 200px;
		overflow: hidden;	
	}
	
	.signupform .last_name .field {
  		width: 178px;
		overflow: hidden;	
  	  }
	
	.signupform .vehicleTypeHolder {
	  width: 300px;
	  background-color: transparent;
	  position: absolute;
	  bottom: 2px;
	  z-index: 1000;
	  text-align: left;
    	  padding: 16px;
      color: #98D2D9;
      font-weight: 300; 
	  overflow: hidden;	
	}
	.signupform .vehicleType {
	  width: 300px;
	  background-color: transparent;
	  position: absolute;
	  bottom: 0px;
	  z-index: 1000;
	  text-align: left;
    	  padding: 16px;
      color: white;
	}
	.signupform  .submitButton {
	  width: 400px;
	}
	
	.signupform .description {
	  text-align: left;
	  font-size: 12px;
	  padding-top: 10px;
	  line-height: 20px;
	  font-weight: 300;
	  width: 400px;
	}

	.signupform .container {
	  padding-left: 34px;
	}

	.signupform checkmark {
	  left: 10px;
	}
}
